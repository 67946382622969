import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import './EmbedOverlay.css';

const socket = io('https://triviagame-woe6.onrender.com');

const EmbedOverlay = () => {
    const [numPlayers, setNumPlayers] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState('');
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        // Listen for player updates
        socket.on('playerJoined', (players) => {
            console.log('Players updated:', players); // Debugging log
            setNumPlayers(Object.keys(players).length);
        });

        // Listen for new question and timer updates
        socket.on('newQuestion', (data) => {
            console.log('New question received:', data); // Debugging log
            setCurrentQuestion(data.question.question); // Assuming data.question is an object with a "question" property
            setTimer(data.timeLeft); // Reset timer
        });

        socket.on('updateTimer', (timeLeft) => {
            console.log('Timer updated:', timeLeft); // Debugging log
            setTimer(timeLeft);
        });

        return () => {
            socket.off('playerJoined');
            socket.off('newQuestion');
            socket.off('updateTimer');
        };
    }, []);

    return (
        <div className="embed-overlay">
            <div className="overlay-info">
                <div className="info-section">
                    <h4>Players:</h4>
                    <span>{numPlayers}</span>
                </div>
                <div className="info-section">
                    <h4>Current Question:</h4>
                    <p>{currentQuestion}</p>
                </div>
                <div className="info-section">
                    <h4>Timer:</h4>
                    <span>{timer} seconds</span>
                </div>
            </div>
        </div>
    );
};

export default EmbedOverlay;
