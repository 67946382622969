import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import './Player.css';

const socket = io('https://triviagame-woe6.onrender.com');

const Player = () => {
    const [roomCode, setRoomCode] = useState('');
    const [playerName, setPlayerName] = useState('');
    const [gameStarted, setGameStarted] = useState(false);
    const [participants, setParticipants] = useState([]); // Store the list of participants
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [answered, setAnswered] = useState(false);
    const [timer, setTimer] = useState(10);
    const [showScoreboard, setShowScoreboard] = useState(false);
    const [scoreboard, setScoreboard] = useState({});
    const [finalRank, setFinalRank] = useState(null); // Store final rank
    const [isGameOver, setIsGameOver] = useState(false); // Track game over
    const [result, setResult] = useState(null); // Store whether the answer was correct

    // Timer duration in seconds
    const timerDuration = 10;

    useEffect(() => {
        // Listen for new questions
        socket.on('newQuestion', (data) => {
            setGameStarted(true);
            setCurrentQuestion(data.question);
            setAnswered(false);
            setShowScoreboard(false);
            setIsGameOver(false);
            setTimer(data.timeLeft); // Reset the timer when a new question is received
        });

        // Listen for timer updates
        socket.on('updateTimer', (timeLeft) => setTimer(timeLeft));

        // Listen for player joining the room
        socket.on('playerJoined', (players) => {
            // Convert the players object to an array of names
            const playerList = Object.values(players).map(player => player.name);
            setParticipants(playerList);
        });

        // Listen for updated scoreboard and results after question
        socket.on('updateScoreboard', (data) => {
            setScoreboard(data.players);
            setShowScoreboard(data.showResults); // Show results for the question

            // Ensure result is defined correctly
            if (data.players[socket.id]) {
                setResult(data.players[socket.id].answeredCorrectly);
            }
        });

        // Listen for final scores and ranks when the game ends
        socket.on('gameOver', (finalScores) => {
            const finalScoresArray = Object.values(finalScores);
            const player = finalScoresArray.find(p => p.name === playerName);
            setFinalRank(player?.rank);
            setScoreboard(finalScoresArray);
            setIsGameOver(true);
        });

        return () => {
            socket.off('newQuestion');
            socket.off('updateTimer');
            socket.off('playerJoined');
            socket.off('updateScoreboard');
            socket.off('gameOver');
        };
    }, [playerName]);

    const joinGame = () => {
        socket.emit('joinGame', { roomCode, playerName });
    };

    const submitAnswer = (option) => {
        if (!answered) {
            socket.emit('answerSubmitted', { roomCode, playerName, answer: option });
            setAnswered(true); // Hide the answer buttons after submission
        }
    };

    // Calculate progress bar color (green to red transition)
    const progressPercentage = (timer / timerDuration) * 100;
    const progressColor = `rgb(${255 - (255 * (timer / timerDuration))}, ${255 * (timer / timerDuration)}, 0)`;

    return (
        <div className="player-container">
            {!gameStarted ? (
                <>
                    <h2>Join a Game</h2>
                    <input
                        type="text"
                        placeholder="Room Code"
                        value={roomCode}
                        onChange={(e) => setRoomCode(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Your Name"
                        value={playerName}
                        onChange={(e) => setPlayerName(e.target.value)}
                    />
                    <button onClick={joinGame}>Join</button>

                    {roomCode && (
                        <div>
                            <h3>Room Code: {roomCode}</h3>
                            <h4>Participants:</h4>
                            <ul>
                                {participants.map((participant, index) => (
                                    <li key={index}>{participant}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </>
            ) : isGameOver ? (
                <div>
                    <h3>Game Over!</h3>
                    <h4>Your Rank: {finalRank === 1 ? '1st' : finalRank === 2 ? '2nd' : finalRank === 3 ? '3rd' : `${finalRank}th`}</h4>
                    <h4>Final Scoreboard:</h4>
                    <ul>
                        {scoreboard.sort((a, b) => b.score - a.score).map((player, index) => (
                            <li key={index}>{player.name}: {player.score} pts</li>
                        ))}
                    </ul>
                </div>
            ) : showScoreboard ? (
                <div>
                    <h3>Results</h3>
                    <h4>You {result ? 'answered correctly!' : 'got it wrong.'}</h4>
                    <h4>Points Earned: {result ? '100' : '0'} pts</h4>
                    <h4>Scoreboard:</h4>
                    <ul>
                        {Object.values(scoreboard).sort((a, b) => b.score - a.score).map((player, index) => (
                            <li key={index}>{player.name}: {player.score} pts</li>
                        ))}
                    </ul>
                </div>
            ) : (
                <div>
                    <div className="progress-bar-container">
                        <div
                            className="progress-bar"
                            style={{ width: `${progressPercentage}%`, backgroundColor: progressColor }}
                        ></div>
                    </div>
                    <h4>Timer: {timer} seconds left</h4>
                    <h3>Question: {currentQuestion?.question}</h3>
                    <div className="answer-grid">
                        {!answered && currentQuestion?.options.map((option, index) => (
                            <button
                                key={index}
                                onClick={() => submitAnswer(option)}
                                className="answer-button"
                            >
                                {option}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Player;
