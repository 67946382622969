import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Player from './components/Player';
import Host from './components/Host';
import EmbedOverlay from './components/EmbedOverlay'; // Import the new component

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/player" element={<Player />} />
                <Route path="/host" element={<Host />} />
                <Route path="/embed" element={<EmbedOverlay />} /> {/* Add the new route */}
                <Route path="/" element={<Host />} />
            </Routes>
        </Router>
    );
};

export default App;
